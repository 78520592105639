.checkout fieldset {
  border: 0;
}
/* fieldset.display {
  border: 1px solid #000;
} */

.checkout form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: white;
  margin: 20px 0 50px 0;
  font-size: large;
  /* width: 60%;
  border: 1px solid purple; */
  /* margin: 80px auto; */
}

.card {
  padding: 1rem;
  background-color: var(--zammBlack);
  margin: 2rem auto;
  width: 55rem;
  max-width: 95%;
}

.package-card {
  background-color: var(--zammLightGreen);
  color: var(--zammGrey);
  width: auto;
  margin-right: 30px;
}

.header {
  margin-bottom: 40px;
}

.package {
  margin: 15px;
}

.checkout input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--zammGrey);
  /* Adjusts the position of the checkboxes on the text baseline */
  vertical-align: -6px;
  outline: none;
  margin-right: 10px;
}

.checkout input[type="radio"]::before {
  display: block;
  content: " ";
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background-color: white;
  font-size: 1.2em;
  transform: translate(3px, 3px) scale(0);
  transform-origin: center;
  transition: all 0.3s ease-in;
}

.checkout input[type="radio"]:checked::before {
  transform: translate(3px, 3px) scale(1);
  transition: all 0.3s cubic-bezier(0.25, 0.25, 0.56, 2);
}

.checkout label {
  cursor: pointer;
  margin: 30px 0 30px 0;
}

.checkout button {
  font: inherit;
  background-color: var(--zammBlack);
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  display: block;
  height: auto;
  align-self: center;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.checkout button:hover {
  background-color: var(--zammLightGreen);
  color: #38015c;
}
/* You can always invert this. Up to you. */
