@import url(https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
* {
  box-sizing: border-box;
}

:root {
  --zammGreen: #12e074;
  --zammGrey: #404040;
  --zammBlack: #151716;
  --zammLightGrey: #aab5af;
  --zammLightGreen: #85deb0;
  --zammGreenTransparent: #85deb040;
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  background-color: #404040;
  background-color: var(--zammGrey);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
}
.MainNavigation_header__2wgkK {
  width: 100%;
  height: 5rem;
  /* background-color: #38015c; */
  background-color: var(--zammBlack);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  color: white;
  font-weight: bold;
}

.MainNavigation_logo__3e2k5 {
  /* font-family: 'Lato', sans-serif; */
  font-size: 2rem;
  /* color: white; */
  color: var(--zammGreen);
  /* margin-bottom: 500rem; */
  display: inline;
}

.MainNavigation_logo-image__2-tKn {
  height: 4rem;
  /* margin: 20px; */
  margin-right: 20px;
  margin-top: 20rem;
}

.MainNavigation_header__2wgkK ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.MainNavigation_header__2wgkK li {
  margin: 0 1rem;
}

.MainNavigation_header__2wgkK a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.MainNavigation_header__2wgkK button[variety="link"] {
  color: red;
}

.MainNavigation_header__2wgkK button {
  font: inherit;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}


.MainNavigation_header__2wgkK button:hover {
  /* background-color: #c291e2; */
  background-color: var(--zammGreen);
  /* color: #38015c; */
  color: var(--zammBlack)
}

.MainNavigation_header__2wgkK a.MainNavigation_active__htnYs {
  color: var(--zammLightGreen);
}


.MainNavigation_header__2wgkK a:hover {
  /* color: #c291e2; */
  color: var(--zammGreen);
}
.ProfileForm_form__jHtNA {
  width: 95%;
  max-width: 25rem;
  margin: 2rem auto;
}

.ProfileForm_control__3IQo_ {
  margin-bottom: 0.5rem;
}

.ProfileForm_control__3IQo_ label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #353336;
  display: block;
}

.ProfileForm_control__3IQo_ input {
  display: block;
  font: inherit;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #38015c;
  padding: 0.25rem;
  background-color: #f7f0fa;
}

.ProfileForm_action__3whz4 {
  margin-top: 1.5rem;
}

.ProfileForm_action__3whz4 button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  background-color: #38015c;
  color: white;
  border: 1px solid #38015c;
}

.ProfileForm_action__3whz4 button:hover {
  background-color: #540d83;
  border-color: #540d83;
}
.UserProfile_profile__10a4r {
  margin: 3rem auto;
  text-align: center;
}

.UserProfile_profile__10a4r h1 {
  font-size: 5rem;
}
.AuthForm_auth__3jJBz {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 6px;
  background-color: #38015c;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: center;
  color: white;
}

.AuthForm_auth__3jJBz h1 {
  text-align: center;
  color: white;
}

.AuthForm_control__3zing {
  margin-bottom: 0.5rem;
}

.AuthForm_control__3zing label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.AuthForm_control__3zing input {
  font: inherit;
  background-color: #f1e1fc;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.AuthForm_actions__1RsBa {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AuthForm_actions__1RsBa button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #9f5ccc;
  border: 1px solid #9f5ccc;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.AuthForm_actions__1RsBa button:hover {
  background-color: #873abb;
  border-color: #873abb;
}

.AuthForm_actions__1RsBa .AuthForm_toggle__1zQny {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.AuthForm_actions__1RsBa .AuthForm_toggle__1zQny:hover {
  background-color: transparent;
  color: #ae82cc;
}
.StartingPageContent_starting__3u0R9 {
  margin: 3rem auto;
  text-align: center;
  color: white;
}

.StartingPageContent_starting__3u0R9 h1 {
  font-size: 5rem;
}

.StartingPageContent_starting__3u0R9 h2 {
  font-size: 4rem;
}
.Profile_profile__2tKGY {
  color: white;
  text-align: center;
}

.Profile_profile__2tKGY form {
  color: white;
  font-size: 1rem;
  margin: 10px;
}

.Profile_profile__2tKGY form input[type="text"],
button {
  color: white;
  font-size: inherit;
  font: inherit;
  margin: 10px;
}

.Profile_profile__2tKGY input::-webkit-input-placeholder {
  color: white;
  font-style: italic;
}

.Profile_profile__2tKGY input:-ms-input-placeholder {
  color: white;
  font-style: italic;
}

.Profile_profile__2tKGY input::placeholder {
  color: white;
  font-style: italic;
}

.Profile_profile__2tKGY form input[type="text"] {
  background-color: var(--zammLightGrey);
  /* box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06); */

  border: 1px solid white;
  padding: 8px;
  padding-left: 12px;
  border-radius: 6px;
  color: white;
  width: 11em;
}

.Profile_profile__2tKGY input[type="text"]:focus {
  outline: none;
  color: var(--zammBlack);
  box-shadow: 0px 0px 5px 5px var(--zammGreenTransparent);;
  /* rgba(255, 255, 255, 0.1); */
}

/* .profile input[type="text"]:hover {
  font-weight: bold;
} */

/* .profile input:hover::placeholder {
  color: var(--zammBlack);
} */

.Profile_profile__2tKGY input:focus::-webkit-input-placeholder {
    color: var(--zammBlack);
}

.Profile_profile__2tKGY input:focus:-ms-input-placeholder {
    color: var(--zammBlack);
}

.Profile_profile__2tKGY input:focus::placeholder {
    color: var(--zammBlack);
}

.Profile_profile__2tKGY button {
  display: block;
  font: inherit;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.5rem;
  margin: auto;
  margin-top: 20px;
}

.Profile_profile__2tKGY button:hover {
  background-color: var(--zammGreen);
  color: var(--zammGrey);
}

.Profile_card__3CGgZ {
  padding: 1rem;
  background-color: var(--zammBlack);
  margin: 2rem auto;
  width: 55rem;
  max-width: 95%;
}

.Card_card__1vq1N {
    border-radius: 12px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}
.Checkout_checkout__1Rgeo fieldset {
  border: 0;
}
/* fieldset.display {
  border: 1px solid #000;
} */

.Checkout_checkout__1Rgeo form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: white;
  margin: 20px 0 50px 0;
  font-size: large;
  /* width: 60%;
  border: 1px solid purple; */
  /* margin: 80px auto; */
}

.Checkout_card__3NjdT {
  padding: 1rem;
  background-color: var(--zammBlack);
  margin: 2rem auto;
  width: 55rem;
  max-width: 95%;
}

.Checkout_package-card__3CgyR {
  background-color: var(--zammLightGreen);
  color: var(--zammGrey);
  width: auto;
  margin-right: 30px;
}

.Checkout_header__3f6vK {
  margin-bottom: 40px;
}

.Checkout_package__3y_A1 {
  margin: 15px;
}

.Checkout_checkout__1Rgeo input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--zammGrey);
  /* Adjusts the position of the checkboxes on the text baseline */
  vertical-align: -6px;
  outline: none;
  margin-right: 10px;
}

.Checkout_checkout__1Rgeo input[type="radio"]::before {
  display: block;
  content: " ";
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background-color: white;
  font-size: 1.2em;
  -webkit-transform: translate(3px, 3px) scale(0);
          transform: translate(3px, 3px) scale(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: all 0.3s ease-in;
}

.Checkout_checkout__1Rgeo input[type="radio"]:checked::before {
  -webkit-transform: translate(3px, 3px) scale(1);
          transform: translate(3px, 3px) scale(1);
  transition: all 0.3s cubic-bezier(0.25, 0.25, 0.56, 2);
}

.Checkout_checkout__1Rgeo label {
  cursor: pointer;
  margin: 30px 0 30px 0;
}

.Checkout_checkout__1Rgeo button {
  font: inherit;
  background-color: var(--zammBlack);
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  display: block;
  height: auto;
  align-self: center;
}

.Checkout_break__1lo_r {
  flex-basis: 100%;
  height: 0;
}

.Checkout_checkout__1Rgeo button:hover {
  background-color: var(--zammLightGreen);
  color: #38015c;
}
/* You can always invert this. Up to you. */

.NotFound_notfound__TWX8U {
    margin: 30px auto;
    width: 40rem;
    background-color: var(--zammLightGrey);
    text-align: center;
    padding: 20px;
}
.Subscription_checkout__1OgIB fieldset {
  border: 0;
}
/* fieldset.display {
    border: 1px solid #000;
  } */

.Subscription_checkout__1OgIB form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: white;
  margin: 20px 0 50px 0;
  font-size: large;
  /* width: 60%;
    border: 1px solid purple; */
  /* margin: 80px auto; */
}

.Subscription_card__2k6vS {
  padding: 1rem;
  background-color: var(--zammBlack);
  margin: 2rem auto;
  width: 55rem;
  max-width: 95%;
}

.Subscription_package-card__2IFVD {
  background-color: var(--zammLightGreen);
  color: var(--zammGrey);
  width: auto;
  margin-right: 30px;
}

.Subscription_header__19FRO {
  margin-bottom: 40px;
  text-align: center;
}

.Subscription_package__1NG32 {
  margin: 15px;
}

.Subscription_checkout__1OgIB input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--zammGrey);
  /* Adjusts the position of the checkboxes on the text baseline */
  vertical-align: -6px;
  outline: none;
  margin-right: 10px;
}

.Subscription_checkout__1OgIB input[type="radio"]::before {
  display: block;
  content: " ";
  width: 10px;
  height: 10px;
  border-radius: 6px;
  background-color: white;
  font-size: 1.2em;
  -webkit-transform: translate(3px, 3px) scale(0);
          transform: translate(3px, 3px) scale(0);
  -webkit-transform-origin: center;
          transform-origin: center;
  transition: all 0.3s ease-in;
}

.Subscription_checkout__1OgIB input[type="radio"]:checked::before {
  -webkit-transform: translate(3px, 3px) scale(1);
          transform: translate(3px, 3px) scale(1);
  transition: all 0.3s cubic-bezier(0.25, 0.25, 0.56, 2);
}

.Subscription_checkout__1OgIB label {
  cursor: pointer;
  margin: 30px 0 30px 0;
}

.Subscription_checkout__1OgIB button {
  font: inherit;
  background-color: var(--zammBlack);
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  display: block;
  height: auto;
  align-self: center;
}

.Subscription_break__QaOoL {
  flex-basis: 100%;
  height: 0;
}

.Subscription_checkout__1OgIB button:hover {
  background-color: var(--zammLightGreen);
  color: #38015c;
}
/* You can always invert this. Up to you. */

