.starting {
  margin: 3rem auto;
  text-align: center;
  color: white;
}

.starting h1 {
  font-size: 5rem;
}

.starting h2 {
  font-size: 4rem;
}