.header {
  width: 100%;
  height: 5rem;
  /* background-color: #38015c; */
  background-color: var(--zammBlack);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  color: white;
  font-weight: bold;
}

.logo {
  /* font-family: 'Lato', sans-serif; */
  font-size: 2rem;
  /* color: white; */
  color: var(--zammGreen);
  /* margin-bottom: 500rem; */
  display: inline;
}

.logo-image {
  height: 4rem;
  /* margin: 20px; */
  margin-right: 20px;
  margin-top: 20rem;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin: 0 1rem;
}

.header a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.header button[variety="link"] {
  color: red;
}

.header button {
  font: inherit;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}


.header button:hover {
  /* background-color: #c291e2; */
  background-color: var(--zammGreen);
  /* color: #38015c; */
  color: var(--zammBlack)
}

.header a.active {
  color: var(--zammLightGreen);
}


.header a:hover {
  /* color: #c291e2; */
  color: var(--zammGreen);
}