@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

* {
  box-sizing: border-box;
}

:root {
  --zammGreen: #12e074;
  --zammGrey: #404040;
  --zammBlack: #151716;
  --zammLightGrey: #aab5af;
  --zammLightGreen: #85deb0;
  --zammGreenTransparent: #85deb040;
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  background-color: var(--zammGrey);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
}