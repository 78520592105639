.profile {
  color: white;
  text-align: center;
}

.profile form {
  color: white;
  font-size: 1rem;
  margin: 10px;
}

.profile form input[type="text"],
button {
  color: white;
  font-size: inherit;
  font: inherit;
  margin: 10px;
}

.profile input::placeholder {
  color: white;
  font-style: italic;
}

.profile form input[type="text"] {
  background-color: var(--zammLightGrey);
  /* box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06); */

  border: 1px solid white;
  padding: 8px;
  padding-left: 12px;
  border-radius: 6px;
  color: white;
  width: 11em;
}

.profile input[type="text"]:focus {
  outline: none;
  color: var(--zammBlack);
  box-shadow: 0px 0px 5px 5px var(--zammGreenTransparent);;
  /* rgba(255, 255, 255, 0.1); */
}

/* .profile input[type="text"]:hover {
  font-weight: bold;
} */

/* .profile input:hover::placeholder {
  color: var(--zammBlack);
} */

.profile input:focus::placeholder {
    color: var(--zammBlack);
}

.profile button {
  display: block;
  font: inherit;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.5rem;
  margin: auto;
  margin-top: 20px;
}

.profile button:hover {
  background-color: var(--zammGreen);
  color: var(--zammGrey);
}

.card {
  padding: 1rem;
  background-color: var(--zammBlack);
  margin: 2rem auto;
  width: 55rem;
  max-width: 95%;
}
